<template>
  <v-alert
    border="bottom"
    class="text-right"
    color="blue lighten-5"
    dark
  >
    <v-container class="justify-sm-end">
    <!--Can only modify item if it is unminted-->
      <v-btn
        class="white rounded-lg mx-2"
        color="primary"
        large
        outlined
        v-show= "!nft.status.minted && !nft.onSale"
        @click="editInfoHandler"
      >
        Edit Item Info
      </v-btn>
      <v-btn
        class="white rounded-lg mx-2"
        color="primary"
        large
        outlined
        v-show= "!nft.status.minted && !nft.onSale"
        @click="editLoyaltyFeeHandler"
      >
        Edit Loyalty Fee
      </v-btn>
      <!--TODO::cancel sale at any time-->
      <v-btn
        class="white rounded-lg mx-2"
        color="primary"
        large
        v-show="nft.onSale"
        outlined
        @click="cancelSaleHandler"
      >
        Cancel Sale
      </v-btn>
      <!--TODO::not show logic-->
      <v-btn
        class="rounded-lg mx-2"
        color="primary"
        large
        v-show="!nft.onSale"
        @click="changePriceHandler"
      >
        Change Price
      </v-btn>
    </v-container>
      <v-dialog v-model="infoDialog" width="500">
      <edit-item-form
        :id = "nft.publicID"
        @close="infoDialog = false"
        @update="infoDialog = false"
      />
  </v-dialog>
    <v-dialog v-model="valueDialog" width="500">
  <change-value-form
    :id = "nft.publicID"
    :currency = "nft.currency"
    @close="valueDialog = false"
    @update="valueDialog = false"
  />
  </v-dialog>
  <v-dialog v-model="loyaltyFeeDialog" width="500">
  <change-loyalty-fee-form
    :id = "nft.publicID"
    :currency = "nft.currency"
    @close="loyaltyFeeDialog = false"
    @update="loyaltyFeeDialog = false"
  />
  </v-dialog>
</v-alert>
</template>

<script>
import { mapGetters } from 'vuex';
import itemAPI from '@/api/item';

export default {
  name: 'NFTListingsActionsBar',
  components: {
    'edit-item-form': () => import('@/features/forms/EditItemForm.vue'),
    'change-value-form': () => import('@/features/forms/ChangePriceForm.vue'),
    'change-loyalty-fee-form': () => import('@/features/forms/ChangeLoyaltyFeeForm.vue'),
  },
  data() {
    return {
      infoDialog: false,
      valueDialog: false,
      loyaltyFeeDialog: false,
    };
  },
  props: {
    isOwner: {
      type: Boolean,
      required: true,
    },
    nft: { // nft object from nft page
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      token: 'user/token',
    }),
  },
  methods: {
    editInfoHandler() {
      this.infoDialog = true;
    },
    editLoyaltyFeeHandler() {
      this.loyaltyFeeDialog = true;
    },
    cancelSaleHandler() {
      itemAPI.cancelSale(this.token, this.nft.publicID).then((res) => {
        console.log(res);
        this.$router.go();
        // refresh page
      });
    },
    changePriceHandler() {
      this.valueDialog = true;
    },
  },
};
</script>
